// // src/components/Navbar.js
// import React from "react";
// import { Link } from "react-router-dom";

// const Navbar = () => {
//   const navbarStyle = {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     padding: "15px 30px",
//     backgroundColor: "#222",
//     color: "#fff",
//     boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//     position: "sticky",
//     top: 0,
//     zIndex: 1000,
//   };

//   const logoStyle = {
//     display: "flex",
//     alignItems: "center",
//   };

//   const logoImgStyle = {
//     height: "50px",
//     marginRight: "15px",
//     borderRadius: "50%", // Adds a circular effect to your logo if desired
//   };

//   const navLinksStyle = {
//     display: "flex",
//     listStyle: "none",
//     margin: 0,
//     padding: 0,
//     gap: "25px",
//   };

//   const linkStyle = {
//     color: "white",
//     textDecoration: "none",
//     fontSize: "18px",
//     fontWeight: "500",
//     transition: "color 0.3s ease",
//   };

//   const hoverEffect = {
//     color: "#f39c12", // Add a subtle orange or brand color hover effect
//   };

//   const buttonStyle = {
//     padding: "10px 20px",
//     backgroundColor: "#f39c12", // Call-to-action color (you can customize)
//     borderRadius: "5px",
//     fontSize: "18px",
//     fontWeight: "bold",
//     color: "#fff",
//     textDecoration: "none",
//     transition: "background-color 0.3s ease",
//   };

//   const buttonHoverEffect = {
//     backgroundColor: "#e67e22",
//   };

//   return (
//     <nav style={navbarStyle}>
//       <div style={logoStyle}>
//         <img src="PT.png" alt="Logo" style={logoImgStyle} />
//         <Link to="/" style={{ ...linkStyle, fontSize: "24px", fontWeight: "bold" }}>
//         PALANATI TECHNOLOGIES
//         </Link>
//       </div>

//       <ul style={navLinksStyle}>
//         <li>
//           <Link
//             to="/"
//             style={linkStyle}
//             onMouseOver={(e) => (e.target.style.color = hoverEffect.color)}
//             onMouseOut={(e) => (e.target.style.color = "white")}
//           >
//             Home
//           </Link>
//         </li>
//         <li>
//           <Link
//             to="/about-us"
//             style={linkStyle}
//             onMouseOver={(e) => (e.target.style.color = hoverEffect.color)}
//             onMouseOut={(e) => (e.target.style.color = "white")}
//           >
//             About Us
//           </Link>
//         </li>
//         <li>
//           <Link
//             to="/services"
//             style={linkStyle}
//             onMouseOver={(e) => (e.target.style.color = hoverEffect.color)}
//             onMouseOut={(e) => (e.target.style.color = "white")}
//           >
//             Services
//           </Link>
//         </li>
//         <li>
//           <Link
//             to="/contact-us"
//             style={linkStyle}
//             onMouseOver={(e) => (e.target.style.color = hoverEffect.color)}
//             onMouseOut={(e) => (e.target.style.color = "white")}
//           >
//             ContactUs
//           </Link>
//         </li>
//         <li>
//           <Link
//             to="/login-signup"
//             style={buttonStyle}
//             onMouseOver={(e) => (e.target.style.backgroundColor = buttonHoverEffect.backgroundColor)}
//             onMouseOut={(e) => (e.target.style.backgroundColor = buttonStyle.backgroundColor)}
//           >
//             Let's Connect !!
//           </Link>
//         </li>
//       </ul>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa"; // For hamburger and close icons

const Navbar = () => {
  const [theme, setTheme] = useState("dark"); // Default theme is dark
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu toggle

  // Function to close mobile menu after clicking a link
  const handleLinkClick = () => {
    setIsMobileMenuOpen(false); // Close the menu when a link is clicked
  };

  // Define styles based on the theme
  const navbarStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 15px",
    background: theme === "dark"
      ? "linear-gradient(90deg, #1d2671 0%, #c33764 100%)" // Dark mode gradient
      : "linear-gradient(90deg, #f79d65 0%, #fbc7d4 100%)", // Light mode gradient
    color: theme === "dark" ? "#fff" : "#000",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease",
    fontSize: "16px",
  };

  const logoStyle = {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    transition: "transform 0.3s ease", // Hover scaling effect
  };

  const logoImgStyle = {
    height: "30px",
    marginRight: "8px",
    borderRadius: "50%", // Circular logo
  };

  const logoTextStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "1.2px",
    color: theme === "dark" ? "#fff" : "#000",
    textTransform: "uppercase",
    transition: "color 0.3s ease",
  };

  const navLinksStyle = {
    display: "flex",
    listStyle: "none",
    margin: 0,
    padding: 0,
    gap: "20px",
    alignItems: "center",
  };

  const linkStyle = {
    color: theme === "dark" ? "#fff" : "#000",
    textDecoration: "none",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px 0",
    transition: "color 0.3s ease",
  };

  const hoverEffect = {
    color: theme === "dark" ? "#f39c12" : "#e67e22",
  };

  const buttonStyle = {
    padding: "8px 18px",
    backgroundColor: "transparent",
    color: theme === "dark" ? "#fff" : "#000",
    border: `2px solid ${theme === "dark" ? "#f39c12" : "#e67e22"}`,
    borderRadius: "30px",
    fontSize: "16px",
    fontWeight: "bold",
    textDecoration: "none",
    cursor: "pointer",
    transition: "all 0.4s ease",
    position: "relative",
    overflow: "hidden",
  };

  const buttonHoverEffect = {
    backgroundColor: theme === "dark" ? "#f39c12" : "#e67e22", // Button hover effect
    color: "#fff",
    boxShadow: `0px 0px 20px rgba(${theme === "dark" ? "243, 156, 18" : "230, 126, 34"}, 0.7)`, // Glowing effect on hover
  };

  // Style for mobile dropdown
  const mobileMenuStyle = {
    display: isMobileMenuOpen ? "block" : "none",
    position: "absolute",
    top: "60px",
    right: "0",
    width: "100%",
    background: theme === "dark" ? "#1d2671" : "#f79d65",
    padding: "20px",
    textAlign: "center",
    zIndex: 999,
  };

  const mobileMenuLinkStyle = {
    color: theme === "dark" ? "#fff" : "#000",
    fontSize: "18px",
    padding: "15px 0", // Proper padding for mobile links
    display: "block",
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)", // Divider for mobile links
    transition: "all 0.3s ease",
  };

  const mobileMenuIconStyle = {
    fontSize: "28px",
    cursor: "pointer",
    color: theme === "dark" ? "#fff" : "#000",
    display: "none", // Hidden on desktop
  };

  // Inline CSS Media Queries for responsiveness
  const mediaStyles = {
    '@media (max-width: 768px)': {
      mobileMenuIconStyle: {
        display: "block", // Show hamburger menu on mobile
      },
      navLinksStyleMobile: {
        display: isMobileMenuOpen ? "block" : "none", // Show menu if open
        flexDirection: "column",
        backgroundColor: theme === "dark" ? "#1d2671" : "#f79d65",
        padding: "20px 0",
        position: "absolute",
        top: "60px",
        width: "100%",
        zIndex: 1000,
      },
      linkStyleMobile: {
        padding: "15px 0", // Proper padding for mobile links
      },
    },
    '@media (min-width: 769px)': {
      mobileMenuIconStyle: {
        display: "none", // Hide hamburger menu on desktop
      },
      navLinksStyleDesktop: {
        display: "flex", // Show normal nav links on desktop
      },
    },
  };

  return (
    <nav style={navbarStyle}>
      {/* Logo Section */}
      <div
        style={logoStyle}
        onMouseOver={(e) => {
          e.currentTarget.style.transform = "scale(1.1)";
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.transform = "none";
        }}
      >
        <img
          src="PT.png" // Update this with your actual logo
          alt="Palanati Technologies Logo"
          style={logoImgStyle}
        />
        <Link to="/" style={logoTextStyle}>
          PALANATI
          <br />
          TECHNOLOGIES
        </Link>
      </div>

      {/* Mobile Menu Icon */}
      <div
        className="mobile-menu-icon"
        style={{ ...mobileMenuIconStyle, ...(window.innerWidth <= 768 && { display: "block" }) }}
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
      </div>

      {/* Navigation Links */}
      <ul style={window.innerWidth > 768 ? navLinksStyle : mobileMenuStyle}>
        <li>
          <Link
            to="/"
            style={window.innerWidth <= 768 ? mobileMenuLinkStyle : linkStyle}
            onClick={handleLinkClick} // Close menu when clicked
            onMouseOver={(e) => (e.target.style.color = hoverEffect.color)}
            onMouseOut={(e) => (e.target.style.color = theme === "dark" ? "#fff" : "#000")}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/about-us"
            style={window.innerWidth <= 768 ? mobileMenuLinkStyle : linkStyle}
            onClick={handleLinkClick} // Close menu when clicked
            onMouseOver={(e) => (e.target.style.color = hoverEffect.color)}
            onMouseOut={(e) => (e.target.style.color = theme === "dark" ? "#fff" : "#000")}
          >
            About Us
          </Link>
        </li>
        <li>
          <Link
            to="/services"
            style={window.innerWidth <= 768 ? mobileMenuLinkStyle : linkStyle}
            onClick={handleLinkClick} // Close menu when clicked
            onMouseOver={(e) => (e.target.style.color = hoverEffect.color)}
            onMouseOut={(e) => (e.target.style.color = theme === "dark" ? "#fff" : "#000")}
          >
            Services
          </Link>
        </li>
        <li>
          <Link
            to="/contact-us"
            style={window.innerWidth <= 768 ? mobileMenuLinkStyle : linkStyle}
            onClick={handleLinkClick} // Close menu when clicked
            onMouseOver={(e) => (e.target.style.color = hoverEffect.color)}
            onMouseOut={(e) => (e.target.style.color = theme === "dark" ? "#fff" : "#000")}
          >
            Contact Us
          </Link>
        </li>
        <li>
          <Link
            to="/login-signup"
            style={window.innerWidth <= 768 ? mobileMenuLinkStyle : buttonStyle}
            onClick={handleLinkClick} // Close menu when clicked
            onMouseOver={(e) => {
              e.target.style.backgroundColor = buttonHoverEffect.backgroundColor;
              e.target.style.color = buttonHoverEffect.color;
              e.target.style.boxShadow = buttonHoverEffect.boxShadow;
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = "transparent";
              e.target.style.color = theme === "dark" ? "#fff" : "#000";
              e.target.style.boxShadow = "none";
            }}
          >
            Let's Connect !!
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
